import React, { useState, useEffect } from 'react'
import { Modal, makeStyles, Fade } from '@material-ui/core'
import Backdrop from '@material-ui/core/Backdrop';
import {popUpContent} from '../config'




function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2, 4, 3),
        boxShadow: theme.shadows[5],
        outline:'none'
    },
}));

const getPopUpContent = (path) => {
    const location = path

    let matches =[]

    popUpContent.forEach(item=> {
        
        if (item.path.replace(/\*+$/, "")===location) {
            matches.push({...item, specificity:100})
            
        } else if (location.startsWith(item.path.replace(/\*+$/, ""))) {
            matches.push({...item, specificity:item.path.replace(/\*+$/, "").length})
        }
    })
     return matches.sort((a,b) => b.specificity - a.specificity)[0]

}

const CustomPopUp = props => {
    const [showPopup, setShowPopup] = useState(false)
    const classes = useStyles()
    let visited = typeof window !== 'undefined' && localStorage['alreadyVisited']
    const [modalStyle] = React.useState(getModalStyle);
    const {actions, title, text} = getPopUpContent(props.location)
    
    useEffect(() => {

        const timer = setTimeout(() => {
            if (!visited & typeof window !== 'undefined') {
                setShowPopup(true)
                localStorage["alreadyVisited"] = true;
            }
        }, 20000);
        return () => clearTimeout(timer);
    }, []);

    const handleClose = () => {
        setShowPopup(false);
    };

    const body = (
        <div style={modalStyle} className={classes.paper}>
            <h2 id="simple-modal-title">{title}</h2>
            <p id="simple-modal-description">
                {text}
            </p>

        </div>
    );
    return (
        <Modal
            open={showPopup}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            onClose={handleClose}
            closeAfterTransition
            disableAutoFocus            
            disablePortal
           
        >

            <Fade mountOnEnter unmountOnExit  in={showPopup}>
            {body}
            </Fade>
        </Modal>
    )

}
export default CustomPopUp