import React from 'react'

const getPath = () => (<g>
    <path d="M22.7,218.93c-0.16,0.45,0.08,0.95,0.53,1.11l5.68,2.02c0.48,0.17,1.01-0.03,1.26-0.46l7.41-12.74l-6.44-13.66L22.7,218.93
    z" fill="currentColor" />
    <g>
        <path d="M217.45,158.53c-0.7,1.61-1.52,3.16-2.48,4.64l-12.08,18.47l28.14,40.78c1.44,2.08,4.28,2.64,6.4,1.25l8.78-5.78
        c1.72-1.13,2.28-3.41,1.28-5.21L217.45,158.53z" fill="currentColor" />
        <path d="M82.15,197.04l-9.4,21.4c-0.19,0.44,0.01,0.96,0.45,1.15l5.52,2.42c0.46,0.2,1.01,0.05,1.29-0.37l10.26-15.03
        C86.83,204.14,83.94,200.87,82.15,197.04z" fill="currentColor" />
        <path d="M11.38,149.26c0.15,0.51,0.58,0.89,1.11,0.97l11.12,1.66c0.27,0.04,0.5,0.21,0.62,0.46l1.52,3.15l-2.18,1.26
        c-0.42,0.24-0.56,0.77-0.32,1.19l5.7,9.84c0.24,0.42,0.77,0.56,1.19,0.32l1.31-0.76l1.79,3.71c0.12,0.25,0.14,0.54,0.05,0.81
        l-2.61,7.92c-0.17,0.53-0.14,1.11,0.1,1.62l1.66,3.47l1.35,2.81l1.84,3.83l5.38,11.41l6.42,13.62l3.21,6.81
        c0.21,0.44,0.72,0.62,1.16,0.42l5.45-2.57c0.46-0.22,0.7-0.73,0.57-1.22l-6.77-25.89h29.96h2.41h4.7c0.1,0,0.2-0.01,0.3-0.02
        c1.26,2.82,3.13,5.26,5.39,7.29c5.24,4.71,12.57,7.16,19.22,6.71c0.2-0.01,0.4-0.02,0.59-0.04c0.27-0.03,0.55,0.11,0.62,0.37
        l4.04,16.06c0.12,0.47,0.59,0.75,1.06,0.63l7.99-2.01c0.47-0.12,0.75-0.59,0.63-1.06l-5.76-22.89c-0.13-0.51-0.62-0.83-1.14-0.88
        c-4.64-0.38-9.2-4.16-12.81-8.95h0c0,0,0-0.01,0-0.01V178c0-0.57-0.14-1.14-0.41-1.64l-6.35-11.79c4.47-4.21,7.35-8.97,9.88-15.69
        c0.44-1.18-0.17-2.49-1.36-2.9c-1.21-0.42-2.55,0.11-3.17,1.23c-2.88,5.26-5.86,8.46-9.25,10.7c-0.2-0.1-0.42-0.16-0.65-0.16H57.2
        c-0.27,0-0.53-0.14-0.68-0.36l-2.22-3.28l2.56-1.48c0.42-0.24,0.56-0.77,0.32-1.19l-5.7-9.84c-0.24-0.42-0.77-0.56-1.19-0.32
        l-3.36,1.95l-4.18-6.16l2.49-3.81c1.36-2.08,1.8-4.65,1.21-7.07c-0.45-1.85-2.6-2.69-4.19-1.64l-9.35,6.17H24.1
        c-0.54,0-1.04,0.29-1.3,0.76l-2.49,4.43c-0.13,0.24-0.38,0.39-0.65,0.41l-10.99,0.73c-0.45,0.03-0.75,0.46-0.62,0.89L11.38,149.26
        z" fill="currentColor" />
        <path d="M206.15,72.71c-2.1-0.67-4.39-0.47-6.34,0.55l-53.34,27.82c-2.14,1.11-2.99,3.73-1.93,5.89l4.11,8.35
        c1.05,2.13,3.59,3.06,5.77,2.1l31.82-14.03v38.93l-36.73,70.49c-0.92,1.77-0.4,3.95,1.23,5.1l3.87,2.74l1.51,1.07l2.79,1.98
        c0.23,0.17,0.48,0.3,0.74,0.41c0.7,0.3,1.48,0.39,2.24,0.26c1.04-0.19,1.97-0.79,2.56-1.67l33.09-48.78l10.2-15.03
        c2.05-3.03,3.53-6.38,4.41-9.89c0.63-2.48,0.97-5.04,0.97-7.63v-27.28l6.94,9.36l9.28,12.54c1.49,2.01,4.24,2.59,6.41,1.36
        l9.19-5.21c1.23-0.7,2.1-1.89,2.4-3.27c0.29-1.38-0.02-2.83-0.86-3.96l-35.47-48.08C210.01,74.85,208.25,73.37,206.15,72.71z" fill="currentColor" />
        <path d="M185.12,48.61c0,9.8,7.94,17.74,17.74,17.74c9.8,0,17.74-7.94,17.74-17.74c0-9.8-7.94-17.74-17.74-17.74
        C193.06,30.86,185.12,38.81,185.12,48.61z" fill="currentColor" />
        <path d="M62.09,146.4c0.48,1.16,1.81,1.71,2.97,1.24l70.33-28.98c1.16-0.48,1.71-1.81,1.24-2.97l-1.99-4.83
        c-0.48-1.16-1.81-1.71-2.97-1.24L61.34,138.6c-1.16,0.48-1.71,1.81-1.24,2.97L62.09,146.4z" fill="currentColor" />
    </g>
</g>)

const DogWalking = (props) =>
    <svg
        width={props.width}
        length={props.height}
        style={{ maxHeight: '100%', ...props.style }}
        viewBox={props.viewBox}
        className={props.className}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        {getPath()}

    </svg>;

export default DogWalking