/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme} from '@material-ui/core/styles';
import "./index.css"
import CustomFooter from "./footer";
import { CssBaseline } from "@material-ui/core";
import HeaderNew from "./header";
import { Button } from "@material-ui/core";
import CookieBar from "./cookieBar";
import CustomPopUp from "./popup";
import { popUpContent } from "../config";



const defaultTheme = createMuiTheme() /**Breakpointları kullanabilmek için */
let theme = createMuiTheme({
  palette: {
    primary: {
      main: '#F1F1F1',
      //light: 'rgb(167, 161, 163)'
    },
    secondary: {
      main: '#F95405',
    },
    poster: {
      main: '#C4D2DE'
    },
    posterTwo: {
      main: '#627288'
    },
  },
  typography: {
    fontFamily: [
      '"Open Sans"',
      'Raleway',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontFamily: ['Raleway', 'sans-serif']
    },
    body1: {
      fontFamily: ['Helvetica Neue', 'sans-serif']
    },
    h6: {
      fontFamily: ['Open Sans', 'sans-serif'],
      fontWeight: 300,
      fontStyle: 'italic',
      letterSpacing: '-0.5px'

    },
    h5: {
      fontFamily: ['Raleway', 'sans-serif'],
      fontWeight: 600,
      letterSpacing: '-0.2px',
      fontSize: '1.2rem'

    },
    promoHeader: {
      fontFamily: ['"Helvetica Neue"', 'sans-serif'],
      fontWeight: 500,
      letterSpacing: '-0.5px',
      fontSize: '4rem',
      lineHeight: '6rem',
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '2rem',
        lineHeight: '3rem',
      }
    },
    blogHeader: {
      fontFamily: ['"Helvetica Neue"', 'sans-serif'],
      fontWeight: 400,
      letterSpacing: '-0.1px',
      fontSize: '2rem',
      lineHeight: '4rem',
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '1.7rem',
        lineHeight: '1.5rem',
      }
    },


  },
});


const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      marginRight: 'auto',
      marginLeft: 'auto',
      marginTop: '0%',
      padding: `0 1.0875rem 1.45rem`,
      maxWidth: '85vW;'
    },
    [theme.breakpoints.up('lg')]: {
      marginRight: 'auto',
      marginLeft: 'auto',
      marginTop: '0%',
      padding: `0 1.0875rem 1.45rem`,
      maxWidth: '85vW;'
    },
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: '0%',
    padding: `0 0.8rem 0.8rem`,
    maxWidth: '98vW;',
    display: 'flex',
    minHeight: '95vH',
    flexDirection: 'column'


  },
  crumbs: {
    listStyleType: 'none',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    padding: 0,
    justifyContent: 'start',
  },
  crumbItem: {
    textDecoration: 'none',
    fontSize: '0.6rem',
    color: 'inherit',
    paddingRight: '0px',
    '&:hover': {
      backgroundColor: 'inherit'
    }

  }
}))

const CustomBreadcrumbs = (props) => {
  const { crumbs } = props
  crumbs[crumbs.length - 1]['crumbLabel'] = props.crumbLabel
  const classes = useStyles()
  console.log(crumbs)
  return (

    <ul className={classes.crumbs}>
      {crumbs.map(crumb =>
      
        <li key={crumb.pathname}><Button size='small' component={Link} to={crumb.pathname[crumb.pathname.length-1]==='/'?crumb.pathname:`${crumb.pathname}/`} className={classes.crumbItem}>{crumb.crumbLabel}{' > '}</Button></li>
      )}
    </ul>
  )
}



const Layout = (props) => {
  const { children, nodeLocale, location } = props
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          url
        }
      }
    }
  `)
  const classes = useStyles()
  return (
    <div data-usecookiebar={true}>
      <MuiThemeProvider theme={theme} >
        <CssBaseline />

        <HeaderNew siteTitle={data.site.siteMetadata?.title || `Prochoice Petfood`}
          nodeLocale={nodeLocale} location={location} />
        <div
          className={classes.root}
        >
          {popUpContent.length > 0 && <CustomPopUp title='Meet us in zoomark, Bologna' text={location.pathname} location={location.pathname}/>}
          {props.crumbs && <CustomBreadcrumbs crumbs={props.crumbs} crumbLabel={props.crumbLabel} />}
          <main>{children}</main>

        </div>
        <CustomFooter locale={nodeLocale} />
        <CookieBar locale={nodeLocale} />

      </MuiThemeProvider>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
