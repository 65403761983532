module.exports = {
    organization:{
    name:"Prochoice",
    legalName:'Atlaspet Pet Ürünleri Pazarlama ve Ticaret A.Ş.',
    foundingDate:'2015',
    address:{
        type:'PostalAddress',
        streetAddress:'Göktürk Cad. No:2 D/7',
        addressLocality:'Göktürk Merkez Mahallesi',
        addressRegion:'Eyüp',
        postalCode:'34077',
        addressCity:'İstanbul',
        addressCountry:'Türkiye'

    },
    contactPoint:{
        type:"ContactPoint",
        contactType: "customer support",
        telephone: "[+90-212-8070140]",
        email:"info@prochoice.com.tr"
    },
    sameAs:[
        "https://www.facebook.com/prochoicepetfood",
        'https://www.instagram.com/prochoicepetfood/'
    ]
    },
    popUpContent: [/* {
        path: '/*',
        title: 'Ahnada bunu buraya yazacaksın',
        text: 'O adamda buraya gelecek.'
    },
    {
        path: '/en/*',
        title: 'Subscribe Now or never',
        text: 'Subscribe to our newsletter and be the first one to learn about novelties'
    } */

]
}