import React from 'react'
import { Link } from 'gatsby'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import { footerMenuItems } from '../intl/translations'
import DogWalking from './custom-icons/dog-walking'


const useStyles = makeStyles(theme => ({
    root: {
        marginTop: `0`,
        backgroundColor: '#121212',
        marginLeft: '0'
    },
    textGrey: {
        color: '#757575',

    },
    footerWrapper: {
        display: 'flex',
        flexDirection: 'row',
        width: '80%',
        marginRight: 'auto',
        marginLeft: 'auto',
        paddingTop: '5%',
        paddingBottom: '5px',
        [theme.breakpoints.down('xs')]:{
            flexDirection:'column'
        }

    },
    menu: {
        flex: '1 1 auto'
    },
    icon: {
        flex: '1 1 25%',
        color: 'white'
    },
    menuWrapper: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom:theme.spacing(5)
    },
    header: {
        color: 'white',
        marginBottom: theme.spacing(2)
    },
    link: {
        textDecoration: 'none',
        '&:hover, &:visited, &:focus': {
            textDecoration: 'none'
        }
    },
    divider: {
        backgroundColor: '#757575',
        width: '100%',
        height: '1px',
        zIndex: '100'
    },
    subtitle: {
        fontSize: '0.8em',
        flex: '1 1 50%'
    },
    bottomFooterWrapper: {
        display: 'flex',
        flexDirection: 'row',
        paddingTop: '10px',
        paddingBottom: '5px'



    },
    footerLink: {
        fontSize: '0.8em',
        flex: '1 1 auto'
    }



}))





function CustomFooter({ locale }) {
    const classes = useStyles()
    return (
        <footer className={classes.root}>
            <Grid container className={classes.footerWrapper}>
                <Grid item className={classes.menu}>
                    <Grid container className={classes.menuWrapper}>
                        <Typography className={classes.header}>{locale === 'en-EU' ? 'Navigate' : 'Ürünler'}</Typography>
                        {footerMenuItems.find(item => item.group === 'products').items[locale].map((el, index) =>
                            <Link key={index} to={el.link} className={`${classes.link} ${classes.textGrey}`}>{el.label}</Link>
                        )}
                    </Grid>

                </Grid>
                <Grid item className={classes.menu}>
                    <Grid container className={classes.menuWrapper}>
                        <Typography className={classes.header}>{locale === 'en-EU' ? 'Who We Are?' : 'Hakkımızda'}</Typography>
                        {footerMenuItems.find(item => item.group === 'help').items[locale].map((el, index) =>
                            <Link key={index} to={el.link} className={`${classes.link} ${classes.textGrey}`}>{el.label}</Link>
                        )}
                    </Grid>

                </Grid>
                <Grid item className={classes.menu}>

                    <Grid container className={classes.menuWrapper}>
                    <Typography className={classes.header}>{locale === 'en-EU' ? 'Follow Us' : 'Bizi Takip Edin'}</Typography>
                        {footerMenuItems.find(item => item.group === 'social').items.map((el, index) =>
                            <a key={index} href={el.link} className={`${classes.link} ${classes.textGrey}`}>{el.label}</a>
                        )}
                    </Grid>

                </Grid>
                <Grid item className={classes.icon}>
                    <DogWalking width='150px' height='auto' viewBox='0 0 256 256' />

                </Grid>
                <Divider className={classes.divider} variant='fullWidth' />
                <Grid item xs={12}>
                    <Grid container className={classes.bottomFooterWrapper}>
                        <Typography className={`${classes.textGrey} ${classes.subtitle}`}>{<> © {new Date().getFullYear()}, Atlaspet A.Ş.</>}</Typography>
                        <Link to={locale === 'en-EU' ? '/en/terms-of-use/' : '/kullanim-kosullari/'} className={`${classes.link} ${classes.textGrey} ${classes.footerLink}`}>{locale === 'en-EU' ? 'Terms of Use' : 'Kullanım Koşulları'}</Link>
                        <Link to={locale === 'en-EU' ? '/en/pdps/' : '/kvkk/'} className={`${classes.link} ${classes.textGrey} ${classes.footerLink}`}>{locale === 'en-EU' ? 'Protection of Personal Data' : 'Kişisel Verilerin Korunması'}</Link>
                    </Grid>
                </Grid>

            </Grid>


        </footer>
    )
}
export default CustomFooter