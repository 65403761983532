import React from 'react'
import Carousel from 'react-material-ui-carousel'
import { Typography } from '@material-ui/core'

const news = {
    'en-EU':{
        items: ["Professional's Choice", 'Satisfied Pet Guarantee']
    },
    'tr-TR':{
        items: ['Profesyonellerin Seçimi', '100% Yeme Garantisi']
    },
}

export default function NewsCarousel(props) {
    return (
    <Carousel
    indicators = {false}
    navButtonsAlwaysInvisible = {true}
    unmountOnExit
    >
       { news[props.locale].items.map((newsItem,index) => <Typography key={index} component='p' variant='subtitle2' align='center' className={props.className}>{newsItem}</Typography>)}
       
    </Carousel>
    )
}