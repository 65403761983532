import React from 'react'

const getPath = () => (<g>
    <rect width="1200" height="800" fill="#E30A17" />
    <circle cx="425" cy="400" r="200" style={{fill:'#ffffff'}} />
    <circle cx="475" cy="400" r="160" style={{fill:'#E30A17'}} />
    <polygon style={{fill:'#ffffff'}} points="583.334,400 764.235,458.779 652.431,304.894 652.431,495.106 764.235,341.221" />
</g>)


const TrFlag = (props) =>
    <svg
        width={props.width}
        length={props.height}
        style={{ maxHeight: '100%', ...props.style }}
        viewBox={props.viewBox}
        className={props.className}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        {getPath()}

    </svg>;

export default TrFlag