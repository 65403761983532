import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { AppBar, Button, IconButton, makeStyles, Toolbar, withStyles } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import NewsCarousel from './newsCarousel';
import { Collapse } from '@material-ui/core';
import IconExpandLess from '@material-ui/icons/ExpandLess'
import IconExpandMore from '@material-ui/icons/ExpandMore'
import Divider from '@material-ui/core/Divider'
import { menuData } from '../intl/translations';
import UkFlag from './custom-icons/uk-flag';
import TrFlag from './custom-icons/tr-flag'







const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props, ref) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));
const drawerWidth = 240
const useStyles = makeStyles(theme => ({
    header: {
        position: 'relative',
        zIndex: 1102,
        backgroundColor: "#fff",
        paddingLeft: "1.0875rem",
        "@media (max-width: 767px)": {
            paddingLeft: 0,
        },
    },
    logo: {
        margin: "auto",
        textAlign: "center",
        maxWidth: 150,
    },
    logoCentered: {
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)"
    },
    logoLeft: {
        flexGrow: 0.5
    },
    newsBar: {
        height: '50px',
        backgroundColor: theme.palette.primary.main,
        width: '100%',
    },
    statusBar: {
        height: '30px',
        backgroundColor: theme.palette.primary.main,
        display:'flex',
        justifyContent:'flex-end',
        alignItems: 'center',
        paddingLeft:'10px',
        paddingRight:'10px'


    },
    newsFlash: {
        lineHeight: '50px',
        zIndex: -1,

    },
    appMenu: {
        width: '100%'
    },
    menuItem: {
        width: drawerWidth,
        [theme.breakpoints.up('md')]: {
            padding: 0,

        }
    },
    menuItemIcon: {
        color: theme.palette.secondary.main,
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    },
    selected: {
        width: drawerWidth,
        color: theme.palette.secondary.main,
        [theme.breakpoints.up('md')]: {
            padding: 0,

        }
    },
    selectedDesktop: {
        textTransform: 'none',
        fontWeight: 'bold',
        borderBottom: 'solid 3px black',
        borderRadius: 0,
        fontFamily: ['Helvetica Neue', 'sans-serif'],
        fontSize: '1.2rem',


    },
    buttonDesktop: {
        textTransform: 'none',
        fontFamily: ['Helvetica Neue', 'sans-serif'],
        fontWeight: 500,
        fontSize: '1.2rem',
        borderBottom: 'solid 3px transparent',
        borderRadius: 0,
    },
    menuWrapper: {
        position: 'absolute',
        display: 'flex',
        flex:'0 0 100%',
        left: '50%;',
        '-ms-transform': 'translateX(-50%);',
        transform: 'translateX(-50%);',
        '&:last-child': {
            marginLeft: 'auto'
        }
    },
    dropdownContainer: {
        position: 'relative',
        display: 'flex',


    },
    dropdownContent: {
        backgroundColor: 'white',
        position: 'absolute;',
        top: '105%',
        left: 0,
        minWidth: '160px;',
        paddingLeft: '20%',


    },
    collapseWrapper: {
        position: 'relative'
    },
    collapseDiv: {
        [theme.breakpoints.up('md')]: {
            position: 'absolute',
            top: '20px',
            width: drawerWidth,
            left: `-${drawerWidth}px`,


        }

    },
    flag:{
        display:'flex',
        flexDirection:'row',
        alignItems:'center',
        gap:'5px',
        
    },
    noDecoration:{
        textDecoration:'none',
        color:'inherit',
        '&:focus, &:hover, &:visited, &:link, &:active':{
            textDecoration:'none'
        }
    }

}))

export default function HeaderNew(props) {
    const [state, setState] = useState({ mobileView: true, drawerOpen: false, anchorEl: null, openItem: null })
    const { mobileView, drawerOpen, anchorEl } = state
    const classes = useStyles()
    const nodeLocale = props.nodeLocale || 'tr-TR'
    let { location } = props || {}

    useEffect(() => {
        const setResponsiveness = () => {
            return window.innerWidth < 900
                ? setState((prevState) => ({ ...prevState, mobileView: true }))
                : setState((prevState) => ({ ...prevState, mobileView: false }));
        };

        setResponsiveness();
        window.addEventListener("resize", () => setResponsiveness());

        return () => {
            window.removeEventListener("resize", () => setResponsiveness());
        }
    }, []);

    const displayMobile = () => {
        const handleClick = (id) => {
            if (state.openItem === id) {
                setState((prevState) => ({ ...prevState, openItem: null }))
            } else {
                setState((prevState) => ({ ...prevState, openItem: id }))
            }

        }
        const handleDrawerOpen = (event) =>
            setState((prevState) => ({ ...prevState, drawerOpen: true, anchorEl: event.currentTarget }));
        const handleDrawerClose = () =>
            setState((prevState) => ({ ...prevState, drawerOpen: false }));
        return (
            <Toolbar>
                <IconButton
                    {...{
                        edge: "start",
                        color: "inherit",
                        "aria-label": "menu",
                        "aria-haspopup": "true",
                        variant: "contained",
                        onClick: handleDrawerOpen,
                    }}
                >
                    <MenuIcon />
                </IconButton>
                <StyledMenu
                    {...{
                        id: 'custom-menu',
                        anchor: "left",
                        open: drawerOpen,
                        onClose: handleDrawerClose,
                        anchorEl: anchorEl
                    }}

                >
                    <List component='nav' className={classes.appMenu} disablePadding>
                        {menuData[nodeLocale].map((item, index) => {
                            const hasSubMenus = item.hasOwnProperty('subMenus')
                            const ListItemLink = () => {
                                return (
                                    <ListItem key={index} button component={Link} to={item.link} className={isCurrent(item.link) ? classes.selected : classes.menuItem}>
                                        <ListItemIcon className={classes.menuItemIcon}>
                                            {item.icon}
                                        </ListItemIcon>
                                        <ListItemText >{item.label}</ListItemText>
                                    </ListItem>
                                )
                            }
                            const ListItemToggle = () => (
                                <ListItem key={index} button onClick={() => handleClick(index)} className={isCurrent(item.link) ? classes.selected : classes.menuItem}>
                                    <ListItemIcon className={classes.menuItemIcon}>
                                        {item.icon}
                                    </ListItemIcon>
                                    <ListItemText >{item.label}</ListItemText>
                                    {index === state.openItem ? <IconExpandLess /> : <IconExpandMore />}
                                </ListItem>
                            )

                            return (
                                <React.Fragment key={index}>
                                    {!hasSubMenus ? <ListItemLink /> :
                                        <>
                                            <ListItemToggle />
                                            <Collapse in={index === state.openItem} timeout="auto" className={classes.collapseWrapper}>
                                                <div className={classes.collapseDiv}>
                                                    <Divider />
                                                    <List component='div' disablePadding>
                                                        {item.subMenus.map((el, index) =>
                                                            <ListItem key={index} button component={Link} to={el.link} className={isCurrent(item.link) ? classes.selected : classes.menuItem}>
                                                                <ListItemText inset>{el.label}</ListItemText>
                                                            </ListItem>

                                                        )}
                                                    </List>

                                                </div>
                                            </Collapse>
                                        </>
                                    }

                                </React.Fragment>
                            )
                        })}
                    </List>
                </StyledMenu>
                <ProchoiceLogo wrapperClass={classes.logoCentered} imgClass={classes.logo} />
            </Toolbar>
        )
    }

    const ProchoiceLogo = ({ wrapperClass, imgClass }) => {
        return (
            <div className={wrapperClass}>
                <Link to={isPartiallyCurrent('/en/') ? '/en' : '/'}>
                    <img src="/images/logo-black.png" className={imgClass} alt={nodeLocale === 'tr-TR' ? 'Prochoice Kedi ve Köpek Mamaları' : 'Prochoice Petfood'} />
                </Link>
            </div>

        )
    }

    const isCurrent = (link) => location.pathname === link
    const isPartiallyCurrent = (link) => {
        if (link.length > 1) {
            return location.pathname.startsWith(link)
        }
        return false
    }






    const DisplayCustomDesktop = (props) => {

        const [open, setOpen] = useState()
        const handleClick = (id) => {
            if (open === id) { setOpen() }
            else {
                setOpen(id)
            }
        }
        return (
            <Toolbar>
                <ProchoiceLogo wrapperClass={classes.logoLeft} imgClass={classes.logo} />
                <div className={classes.menuWrapper}>
                    {menuData[props.nodeLocale].map((item, index) => {
                        const hasSubMenus = item.hasOwnProperty('subMenus')
                        const ButtonAsLink = () =>
                            <Button
                                key={index}
                                color="inherit"
                                className={(isCurrent(item.link) || isPartiallyCurrent(item.link)) ? classes.selectedDesktop : classes.buttonDesktop}
                                component={Link}
                                to={item.link}
                            >
                                {item.label}
                            </Button>
                        const ButtonAsCollapse = () =>
                            <Button
                                value={index}
                                key={index}
                                color="inherit"
                                className={(isCurrent(item.link) || isPartiallyCurrent(item.link)) ? classes.selectedDesktop : classes.buttonDesktop}
                                onClick={() => handleClick(index)}
                                onMouseEnter={() => handleClick(index)}

                            >
                                {item.label}
                            </Button>


                        return (
                            <React.Fragment key={`menu-header-${index}`}>

                                {!hasSubMenus ?
                                    <div key={index} className={classes.dropdownContainer}><ButtonAsLink /></div>
                                    :
                                    <div tabIndex={0} role="button" key={index} className={classes.dropdownContainer} onMouseLeave={() => handleClick(index)}>
                                        <ButtonAsCollapse />
                                        <Collapse timeout="auto" in={index === open}>
                                            <Divider />
                                            <List component="div" className={classes.dropdownContent}>
                                                {item.subMenus.map((item, index) => {
                                                    return (
                                                        <ListItem key={index} button component={Link} to={item.link} className={isCurrent(item.link) ? classes.selected : classes.menuItem}>

                                                            <ListItemText >{item.label}</ListItemText>
                                                        </ListItem>
                                                    )
                                                })}
                                            </List>
                                        </Collapse>
                                    </div>
                                }
                            </React.Fragment>
                        )
                    })}
                </div>{ }
            </Toolbar>
        )

    }





    return (
        <header>
            <div className={classes.statusBar}>
            <Link to={isPartiallyCurrent('/en/') ? '/' : '/en/'} className={classes.noDecoration}>
                   {isPartiallyCurrent('/en/') ? <div className={classes.flag}><TrFlag viewBox="0 0 1200 800"  width='20px'/>TR</div> : <div className={classes.flag}><UkFlag viewBox="0 0 60 30"  width='28px'/>EN</div>} 
                </Link>
                </div>
            <AppBar className={classes.header} position='static'>
                {mobileView ? displayMobile() : <DisplayCustomDesktop nodeLocale={nodeLocale} />}
            </AppBar>
            <div className={classes.newsBar}>
                <NewsCarousel locale={nodeLocale} className={classes.newsFlash} />
            </div>
        </header>
    )
}



